import React, { useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../lib/main-context";
import { SALLIE_DASHBOARD } from "../constants/DashboardTranslation";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SadSallie from "../images/Sallie-Oops.png";
import Sallie_tail from "../images/Sallie_tail2.gif";

// Sallie takes up one row of whatever container she is in. 2 cols are for the img, and 10 are for the text. Text box shrinks to fit smaller messaged
const Sallie = ({ user, text, sad, isCG, isProvider }) => {
  const { language } = useContext(PreferredLanguageContext);
  const { patient } = useContext(PatientContext);

  let gif = Sallie_tail;
  let img = gif;

  if (sad) {
    img = SadSallie;
  }

  return (
    <Row>
      <Col
        md={2}
        style={{ minWidth: "125px" }}
        className="d-flex align-items-center justify-content-center"
      >
        <img
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          src={img}
          alt="Dog named Sallie"
        />
      </Col>
      <Col className="p-0" style={{ maxWidth: "fit-content" }}>
        <div className="sallie">
          <h6>{text}</h6>
        </div>
      </Col>

      {(isProvider || isCG) && (
        <Col
          className="p-0 d-flex justify-content-center align-items-center"
          style={{ maxWidth: "fit-content" }}
        >
          <div
            style={{
              paddingBottom: "0.5rem",
              margin: "0.5rem",
              whiteSpace: "nowrap",
            }}
          >
            <h6>
              {isProvider && patient?.caregiverId ? (
                <>
                  Viewing caregiver{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {patient.caregiverName?.toUpperCase()}
                  </span>
                  {" "}tracking for patient:
                  <br></br>
                  <span style={{ fontWeight: "bold", fontSize: "1.8rem" }}>
                    {patient.firstName} {patient.lastName}
                  </span>
                  {patient.externalId && `, ID: ${patient.externalId}`}
                </>
              ) : (
                <>
                  {isProvider
                    ? SALLIE_DASHBOARD[language].patient_info.provider
                    : SALLIE_DASHBOARD[language].patient_info.non_provider}
                  <span style={{ fontWeight: "bold", fontSize: "1.8rem" }}>
                    {patient?.firstName} {patient?.lastName}
                  </span>
                  {patient?.externalId && `, ID: ${patient.externalId}`}
                </>
              )}
            </h6>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default Sallie;
