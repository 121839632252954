import React, { useState, useEffect, useContext } from "react";
import "./basicinfo.scss";

// Hooks
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

// Bootstrap
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";

// Validation Rules
import SignupSchema from "./validations/registerValidation";
import {
  registerUser,
  registerPatient,
  connectInvitedPatientWithP,
} from "../../api/auth";
import {
  getProviderTokenInformation,
  getProviderID,
  sendConfirmEmail,
  checkExistingList,
} from "../../api/helpReq";
import { getDiagnoses, getProviderTypes, postDiagnoses, } from "../../api/TBIRequests";

// Components
import Sallie from "../Sallie";
import PrivacyPagePatient from "../PrivacyPagePatient";
import LinkButton from "../StyledComponents/LinkButton";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";
import PolicyComponent from "./PolicyComponent";

// lib
import useListSelector from "../../lib/useListSelector";

// API
import CountryList from "../../api/countryList.json";
import ClinicList from "../../api/clinicList.json";
import { verifyLicenseKey } from '../../api/paymentRequest';

// Translation
import { PreferredLanguageContext } from '../../lib/main-context'
import {
  STEP_1A,
  BASIC_INFORMATION,
  UNDER_13,
  REGISTRATION_TYPE,
  PROVIDER_TYPE,
  NAME,
  EMAIL,
  DOB,
  COUNTRY,
  CLINIC,
  ZIPCODE,
  PASSWORD,
  MESSAGES_AND_ALERTS,
  TERMS_AND_CONDITIONS,
  SAVE_AND_CONTINUE,
} from '../../constants/OnboardingTranslation'
import DiagnosisSelector from "../DiagnosisSelector";

const BasicInfo = ({
  setOnboardingPercent,
  setUser,
  setPatient,
  setPatientList,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [viewPolicyPatient, setViewPolicyPatient] = useState(false);
  const [alertPolicy, setAlertPolicy] = useState(true);
  const [messagePolicy, setMessagePolicy] = useState(true);
  const [providerEmail, setProviderEmail] = useState(null);
  const [providerTypes, setProviderTypes] = useState(null);
  const [providerId, setProviderId] = useState(null);
  const [diagnoses, setDiagnoses] = useState([]);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [registrationType, setRegistrationType] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [readAlert, setReadAlert] = useState(false);

  const url = new URLSearchParams(location.search);
  const token = url.get("token");
  const isProviderForm =
    token &&
    !token.startsWith("invitedPatient");
  const isInvitedPatientByProvider =
    token && token.startsWith("invitedPatientP");
  const { register, handleSubmit, errors, setError, setValue } = useForm({
    resolver: yupResolver(SignupSchema({ isProviderForm })),
  });

  const { language } = useContext(PreferredLanguageContext);

  useEffect(() => {
    setOnboardingPercent(10);

    getDiagnoses()
    .then((res) => {
      setDiagnoses(res.data)
    })
    .catch((err) => {
      console.error(err);
      history.push("/oops");
    });

    if (location.search.length > 0) {
      setValue("email", url.get("email"));
      setValue("firstName", url.get("first"));
      setValue("lastName", url.get("last"));
      if (isProviderForm) {
        const data = { token };
        getProviderTokenInformation({ data })
          .then((res) => {
            const { data } = res;
            // setValue("email", data.providerEmail);
            // Assuming we are hiding the email field for providers in the JSX
            setProviderEmail(data.providerEmail);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      } else if (isInvitedPatientByProvider) {
        const data = { token };
        getProviderID({ data })
          .then((res) => {
            const { data } = res;
            setProviderId(data.provider_id);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      }
    }
  }, []);

  const handleRegistrationTypeChange = (event) => {
    setRegistrationType(event.target.value);
  };
  const handleSelectDiagnosis = (id) => {
    if (selectedDiagnoses.includes(id)) {
      setSelectedDiagnoses(selectedDiagnoses.filter(d => d != id))
    } else {
      setSelectedDiagnoses([...selectedDiagnoses, id])
    }
  }
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const [returnedData, setReturnedData] = useState([]);

  const {
    toggleFromSelectedList,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  useEffect(() => {
    setOnboardingPercent(10);
    if (location.search.length > 0) {
      setValue("email", url.get("email"));
      setValue("firstName", url.get("first"));
      setValue("lastName", url.get("last"));
      if (isProviderForm) {
        const data = { token };
        getProviderTokenInformation({ data })
          .then((res) => {
            const { data } = res;
            // setValue("email", data.providerEmail);
            // Assuming we are hiding the email field for providers in the JSX
            setProviderEmail(data.providerEmail);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      }
    }
  }, []);

  useEffect(() => {
    basicInfoFormFill();
  }, []);

  const basicInfoFormFill = async () => {
    try {
      /* retrieve provider types from DB */
      getProviderTypes().then((res) => {
        const { data } = res;
        setProviderTypes(data);
      });

    } catch {
      history.push("/oops");
    }
  };

  const onSubmit = async (data) => {
    if (!agreed) {
      setReadAlert(true);
      return;
    }
    let patientType;
    let patientSubType;
    if (data.userType == "patient") {
      patientType = 'Other';
      patientSubType = 'Autism';
      data.patientType = patientType;
      data.patientSubType = patientSubType;
    }
    if (providerEmail) {
      data.email = providerEmail;
    }
    if (isProviderForm) {
      if (token.substring(0, 9) == "caregiver") {
        data.referral = "caregiver";
      } else if (token.substring(0, 7) == "patient") {
        data.referral = "patient";
      }
      data.userType = "provider";
    }

    try {
      setIsLoading(true);
      if (data.userType === "provider" || isProviderForm) {
        data.providerToken = token;
        data.messagePolicy = messagePolicy;
        data.alertPolicy = alertPolicy;
      }

      data.email = data.email.toLowerCase();

      const registerUserResponse = await registerUser(data);
      const responseUserData = registerUserResponse.data;
      setUser({ ...responseUserData, patientSubType, patientType });
      const { firstName, lastName, email, userType, userId } = responseUserData;

      if (userType !== 'provider') {
        await sendConfirmEmail({
          email: email,
          name: firstName + " " + lastName,
        });
      }

      await checkExistingList({
        email: email,
      });

      if (userType === "caregiver") {
        history.push("/RegisterPatient", {
          fromPatientControl: false,
        });
      } else if (
        (userType == "patient" && patientType == "Dementia") ||
        patientType == "Other"
      ) {
        // For those are not tbi patients
        data.relationshipType = "self";
        // data.status = combinedValues;
        const registerPatientResponse = await registerPatient(data);
        const responsePatientData = registerPatientResponse.data;
        setPatient(responsePatientData);
        postDiagnoses({data: selectedDiagnoses, patientId: responsePatientData.patientId});
        if (isInvitedPatientByProvider) {
          await connectInvitedPatientWithP({
            patientId: responsePatientData.patientId,
            userId: userId,
            providerId: providerId,
          });
        }

        history.push("/laterSymptoms", {
          fromPatientControl: location.state?.fromPatientControl,
        })
      } else { //provider
        try {
          const response = await verifyLicenseKey({ userId: userId, licenseKey: "SALLIEFREEACCOUNT" });
          if (response.status === 200) {
            console.log('setVerified is true');
          } else {
            console.error('oops error with provider license ' + userId);
          }
        } catch (error) {
          console.error('Error verifying license key. ' + userId);
        }
        history.push("/patientControl"); // take the provider to patient and caregiver selector screen
      }
    } catch (err) {
      console.log(err);
      if (err.message && err.message.includes("409")) {
        setError("email", {
          type: "emailTaken",
          message: "Email is already taken!",
        });
      } else if (err.message && err.message.includes("406")) {
        setError("email", {
          type: "emailNotValid",
          message: "This is not valid email.",
        });
      } else {
        history.push("/oops");
      }
    } finally {
      setIsLoading(false);
    }
  };

  let SallieMsg = BASIC_INFORMATION[language];

  if (
    errors.dob &&
    errors.dob.message &&
    errors.dob.message === "You must be 13 or older"
  ) {
    SallieMsg = UNDER_13[language];
  }

  return (
    <>
      <div style={{ display: viewPolicyPatient ? "block" : "none" }}>
        <PrivacyPagePatient
          firstName={firstName}
          lastName={lastName}
          goback={() => {
            setViewPolicyPatient(false);
          }}
          onClick={() => {
            setAgreed(true);
            setViewPolicyPatient(false);
          }}
        />
      </div>
      <div
        style={{ display: viewPolicyPatient ? "none" : "block" }}
      >
        <OnboardingStepHeader text={STEP_1A[language]} />
        <Sallie text={SallieMsg} />
        <Form
          autoComplete="on"
          action="/post"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Row>
            <Col md={12}>
              {!isProviderForm ? (
                <>
                  <Form.Group controlId="userType" as={Row} className="registration-type">
                    <Form.Label column className="registration-type-label">
                      {REGISTRATION_TYPE[language].label}<span className="required">*</span>
                    </Form.Label>
                    <Col sm={12}>
                      <Row className="registration-type-radio-row">
                        <Col sm={2}>
                          <Form.Check
                            inline
                            type="radio"
                            label={REGISTRATION_TYPE[language].patient}
                            name="userType"
                            value="patient"
                            checked={registrationType === "patient"}
                            onChange={handleRegistrationTypeChange}
                            id="registrationTypePatient"
                            className="registration-type-radio"
                            ref={register}
                            isInvalid={errors.userType}
                          />
                        </Col>
                        <Col sm={2}>
                          <Form.Check
                            inline
                            type="radio"
                            label={REGISTRATION_TYPE[language].caregiver}
                            name="userType"
                            value="caregiver"
                            checked={registrationType === "caregiver"}
                            onChange={handleRegistrationTypeChange}
                            id="registrationTypeCaregiver"
                            className="registration-type-radio"
                            ref={register}
                            isInvalid={errors.userType}
                          />
                        </Col>
                        <Col sm={4}>
                          <Form.Check
                            inline
                            type="radio"
                            label={REGISTRATION_TYPE[language].provider}
                            name="userType"
                            value="provider"
                            checked={registrationType === "provider"}
                            onChange={handleRegistrationTypeChange}
                            id="registrationTypeProvider"
                            className="registration-type-radio"
                            ref={register}
                            isInvalid={errors.userType}
                          />
                        </Col>
                      </Row>
                      <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                        {errors.userType && errors.userType.message}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {registrationType === "provider" && (
                    <>
                      <Form.Group controlId="providerType">
                        <Form.Label>
                          {PROVIDER_TYPE[language].label} <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          autoFocus={true}
                          name="providerType"
                          as="select"
                          ref={register}
                          isInvalid={errors.providerType}
                        >
                          {providerTypes
                            ? providerTypes.map((providerType) => (
                              <option
                                key={providerType.constantString}
                                value={providerType.constantString}
                              >
                                {PROVIDER_TYPE[language][providerType.constantString]}
                              </option>
                            ))
                            : null}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                          {errors.providerType && errors.providerType.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}
                  {errorMessage && (
                    <Form.Control.Feedback
                      style={{ display: "block" }}
                      type="invalid"
                    >
                      Please select one of the status.
                    </Form.Control.Feedback>
                  )}
                </>
              ) : (
                <Form.Group controlId="providerType">
                  <Form.Label>
                    {PROVIDER_TYPE[language].label} <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    autoFocus={true}
                    name="providerType"
                    as="select"
                    ref={register}
                    isInvalid={errors.providerType}
                  >
                    {providerTypes
                      ? providerTypes.map((providerType) => (
                        <option
                          key={providerType.constantString}
                          value={providerType.constantString}
                        >
                          {PROVIDER_TYPE[language][providerType.constantString]}
                        </option>
                      ))
                      : null}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.providerType && errors.providerType.message}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Col>

            {(registrationType === "patient") && (
              <>
                <h6>
                  Diagnoses for Patient
                  <span className="required">*</span>
                </h6>
                <Col md={12} className="mt-3">
                    <DiagnosisSelector
                      items={diagnoses}
                      selectedItems={selectedDiagnoses}
                      selectItem={handleSelectDiagnosis}
                    />
                </Col>
              </>
            )}

            <Col md={3}>
              <LabelledInput
                name="firstName"
                required
                label={NAME[language].first}
                autoComplete="given-name"
                onChange={handleFirstNameChange}
                inputRef={register}
                errors={errors}
              />
            </Col>

            <Col md={3}>
              <LabelledInput
                name="lastName"
                autoComplete="family-name"
                label={NAME[language].last}
                onChange={handleLastNameChange}
                required
                inputRef={register}
                errors={errors}
              />
            </Col>

            {!isProviderForm && (
              <Col md={6}>
                <LabelledInput
                  name="email"
                  type="email"
                  label={EMAIL[language]}
                  autoComplete="username"
                  required
                  inputRef={register}
                  errors={errors}
                />
              </Col>
            )}

            <Col md={6}>
              <LabelledInput
                name="dob"
                type="DOB"
                label={DOB[language]}
                required
                inputRef={register}
                errors={errors}
              />
            </Col>

            <Col md={6}>
              <Form.Group controlId="clinic">
                <Form.Label>{CLINIC[language]}</Form.Label>
                <Form.Control
                  name="clinic"
                  as="select"
                  ref={register}
                  defaultValue="US"
                >
                  {Object.keys(ClinicList)
                    .sort()
                    .map((clinicName) => (
                      <option
                        key={ClinicList[clinicName]}
                        value={ClinicList[clinicName]}
                      >
                        {clinicName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <LabelledInput
                name="postalCode"
                autoComplete="postal-code"
                label={ZIPCODE[language]}
                required
                inputRef={register}
                type="text"
                errors={errors}
              />
            </Col>

            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label>{COUNTRY[language]}</Form.Label>
                <Form.Control
                  name="country"
                  as="select"
                  ref={register}
                  defaultValue="US"
                >
                  {Object.keys(CountryList)
                    .sort()
                    .map((countryName) => (
                      <option
                        key={CountryList[countryName]}
                        value={CountryList[countryName]}
                      >
                        {countryName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Row>
              <Col md={6}>
                <LabelledInput
                  name="password"
                  type="password"
                  label={PASSWORD[language].password}
                  required
                  autoComplete="new-password"
                  inputRef={register}
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <LabelledInput
                  type="password"
                  name="passwordConfirmation"
                  label={PASSWORD[language].confirm_password}
                  autoComplete="new-password"
                  required
                  inputRef={register}
                  errors={errors}
                />
              </Col>
            </Row>
            {(registrationType == "provider" ||
              isProviderForm) && (
                <PolicyComponent
                  label={MESSAGES_AND_ALERTS[language].messages}
                  radioName="radio-type-1"
                  idYes="field-yes-1"
                  idNo="field-no-1"
                  policyValue={messagePolicy}
                  setPolicyValue={setMessagePolicy}
                />
              )}
            {(registrationType == "provider" ||
              isProviderForm) && (
                <PolicyComponent
                  label={MESSAGES_AND_ALERTS[language].alerts}
                  radioName="radio-type-2"
                  idYes="field-yes-2"
                  idNo="field-no-2"
                  policyValue={alertPolicy}
                  setPolicyValue={setAlertPolicy}
                />
              )}

            <Col md={12} className="terms-and-conditions">
              <div>
                <span>{TERMS_AND_CONDITIONS[language].accept} </span>
                <LinkButton type="button" onClick={() => setViewPolicyPatient(true)}>
                  <h5>{TERMS_AND_CONDITIONS[language].terms_and_conditions}</h5>
                </LinkButton>
              </div>
              {readAlert && !agreed && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  Please click and read the green words.
                </Form.Control.Feedback>
              )}
            </Col>


            <Col md={12}>
              <SubmitButton disabled={isLoading} type="submit">
                {SAVE_AND_CONTINUE[language]} &gt;
              </SubmitButton>
            </Col>
          </Form.Row>
        </Form>
      </div>
    </>
  );
};

export default BasicInfo;
