import React, { useContext, useEffect, useState } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { SEND_MESSAGES } from "../../constants/MessagesTranslation";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import sendMessageSchema from "./validations/sendMessageSchema";

import { Col, Form, Row } from "react-bootstrap";
import ComponentWrapper from "./ComponentWrapper";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";

import { sendMessage } from "../../api/messageRequests";

const SendMessage = ({ recipientId, recipientName, userType }) => {
  const { language } = useContext(PreferredLanguageContext);
  const [showInput, setShowInput] = useState(true);
  const [messageSent, setMessageSent] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(sendMessageSchema),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    data.recipientId = recipientId;
    data.userType = userType;
    sendMessage(data)
      .then(() => {
        setMessageSent(true);
        setShowInput(false);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  return (
    <ComponentWrapper style={{ width: '40rem' }}>
      {recipientId ? (
        showInput && (
          <Row className="p-2">
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <LabelledInput
                  required
                  name="body"
                  label={`${SEND_MESSAGES[language].send_message_to}${recipientName}`}
                  errors={errors}
                  inputRef={register}
                  inputAs="textarea"
                  rows={5}
                />
                <SubmitButton type="submit" disabled={submitDisabled} className="mt-2">
                  {SEND_MESSAGES[language].send}
                </SubmitButton>
              </Form>
            </Col>
          </Row>
        )
      ) : (
        <p>Please select a provider from the dropdown before continuing.</p>
      )}
      {messageSent &&
        <Row className="p-2">
          <Col className="text-center">
            {SEND_MESSAGES[language].message_sent} {recipientName}.
          </Col>
        </Row>
      }
    </ComponentWrapper>
  );
};

export default SendMessage;
