/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { useHistory, useLocation } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Row, Col, Form } from "react-bootstrap";
import MelilloBrain1 from '../../images/melillo-brain1.jpg';
import MelilloBrain2 from '../../images/melillo-brain2.jpg';

import {
  getTBISymptoms,
  postRegisterFactors,
} from "../../api/TBIRequests";
import useListSelector from "../../lib/useListSelector";

import MedicalListSelector from "../MedicalListSelector";
import Sallie from "../Sallie";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";
import customizeMessages from "../../lib/customizeMessages";

import {
  MEDICAL_SYMPTOMS,
  SALLIE_BRAIN,
  SYMPTOM_SELECTOR,
  SAVE_AND_CONTINUE,
} from "../../constants/OnboardingTranslation";

const SelectLaterSymptoms = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);
  const [loading, setLoading] = useState(true);
  const [returnedData, setReturnedData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [dateOfTBI, setDateOfTBI] = useState(undefined);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [headacheParts, setHeadacheParts] = useState([]);

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const history = useHistory();
  const location = useLocation();
  const { language } = useContext(PreferredLanguageContext);

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  // Maps to Step 4 in the DB, Steps 2 and 3 have been removed from the workflow
  const getPossibleMessages = ({ getPronoun, isOtherGender }) => ({
    mainHeader: {
      tbiPatient: MEDICAL_SYMPTOMS[language],
      caregiver: MEDICAL_SYMPTOMS[language],
    },
    sallieText: {
      tbiPatient: SALLIE_BRAIN[language].tbiPatient,
      caregiver: SALLIE_BRAIN[language].caregiver,
    },
    duplicatesText: {
      tbiPatient: SYMPTOM_SELECTOR[language].errors.duplicate,
      caregiver: SYMPTOM_SELECTOR[language].errors.duplicate,
    },
    noSymptomsText: {
      tbiPatient: SYMPTOM_SELECTOR[language].errors.patient,
      caregiver: SYMPTOM_SELECTOR[language].errors.caregiver,
    },
    selectHeadacheLocationText: {
      tbiPatient:
        SYMPTOM_SELECTOR[language].errors.headache_location,
      caregiver:
        SYMPTOM_SELECTOR[language].errors.headache_location,
    },
  });
  const getMessage = customizeMessages({ user, getPossibleMessages });

  const changeErrorMsg = (errorCase) => {
    const errorMsgList = {
      hasDuplicates: getMessage("duplicatesText"),
      noSymptom: getMessage("noSymptomsText"),
      needHeadAcheLocation: getMessage("selectHeadacheLocationText"),
    };
    setErrorMsg(errorMsgList[errorCase]);
    setShowErrorMsg(true);
  };

  const categories = [
    "motor",
    "sensory",
    "emotional",
    "behavioral",
    "cognitive",
    "immune",
    "metabolic"
  ];
  const currentCategory = categories[currentCategoryIndex];

  const processDataForSending = () => {
    return Object.entries(selectedData).flatMap(([category, itemList]) => {
      return itemList.map((item) => ({
        factor: item,
        category: "medical",
        subcategory: category,
        datetimeOfSymptom: new Date(),
        hadSymptom: true,
      }));
    });
  };

  const createItemListFromRes = (res) => {
    return categories.map((category) => {
      const items = res.data
        .filter((item) => item.subcategory === category)
        .map((item) => item.factor);
      return { category, items };
    });
  };

  useEffect(() => {
    location.state?.fromPatientControl
      ? setOnboardingPercent(40)
      : setOnboardingPercent(40);
    getTBISymptoms()
      .then((res) => {
        const itemList = createItemListFromRes(res);

        setReturnedData(itemList);
        initialize(itemList);
        setLoading(false);
      })
      .catch(() => {
        history.push("/oops");
      });
    // if we include initialize in the deps we get an infinite loop
    // TODO: Extract logic of init into outer function to use dep arr correctly
  }, []);

  const handlePrev = () => {
    if (currentCategoryIndex > 0) {
      setCurrentCategoryIndex((prev) => prev - 1);
    }
  };

  const handleNextOrSubmit = () => {
    if (currentCategoryIndex < categories.length - 1) {
      setCurrentCategoryIndex((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    let processedData = processDataForSending();

    if (processedData.length === 0) {
      changeErrorMsg("noSymptom");
      return;
    }

    processedData = processedData.filter((item) => item.factor !== "None");

    setSubmitting(true);

    try {
      postRegisterFactors({
        patientId: patient.patientId,
        data: processedData,
      }).then(() => {
        {
          //add more symptoms,if there's no tracking factors
          location?.state?.fromPatientDashboard ?
            history.push("/selectTopSymptoms", {
              forwardData: { selectedData, processedData, dateOfTBI },
              fromPatientControl: location.state?.fromPatientControl,
              fromPatientDashboard: true,
            }) :
            history.push("/selectTopSymptoms", {
              forwardData: { selectedData, processedData, dateOfTBI },
              fromPatientControl: location.state?.fromPatientControl,
            })
        }
        ;
      });
    } catch (err) {
      console.log("some error in SelectLaterSymptoms handleSubmit");
      console.log(err);
      history.push("/oops");
    }
  };

  const tabs = {
    motor: 'Motor Symptoms',
    sensory: 'Sensory Symptoms',
    emotional: 'Emotional Symptoms',
    behavioral: 'Behavioral Symptoms',
    cognitive: 'Cognitive Symptoms',
    immune: 'Immune Symptoms',
    metabolic: 'Metabolic Symptoms',
  };

  const colors = {
    motor: "#b3e5fc",
    sensory: "#ffccbc",
    emotional: "#dab7ec",
    behavioral: "#ffdadb",
    cognitive: "#ffdcaf",
    immune: "#fff3c4",
    metabolic: "#cef3c4",
  };

  const colorsSelected = {
    motor: "#b3e5fc",
    sensory: "#ffccbc",
    emotional: "#dab7ec",
    behavioral: "#ffdadb",
    cognitive: "#ffdcaf",
    immune: "#fff3c4",
    metabolic: "#cef3c4",
  };

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <>
          <Row className="justify-content-center">
            <Col xs={10} md={8}>
              <OnboardingStepHeader text={getMessage("mainHeader")} />
              <Sallie text={getMessage("sallieText")} style={{ textAlign: "left" }} />
              <Row>
                <Col sm={6}>
                  <img src={MelilloBrain1} style={{ display: 'inlineBlock', height: "18rem", width: "100%", marginBottom: "2rem", marginTop: "2rem" }}></img>
                </Col>
                <Col sm={6}>
                  <img src={MelilloBrain2} style={{ display: 'inlineBlock', height: "18rem", width: "100%", marginBottom: "2rem", marginTop: "2rem" }}></img>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-between" style={{ marginTop: "1rem" }}>
            <Col sm={12}>
              <h5 style={{ textAlign: "center" }}>{SYMPTOM_SELECTOR[language].category_header}</h5>
            </Col>
          </Row>
          <Tab.Container id="left-tabs-example" activeKey={currentCategory}>
            <Row style={{ marginTop: "0.5rem" }}>
              <Col sm={3} >
                <Nav fill justify variant="tabs" className="flex-column">
                  {returnedData.map((itemList, i) => (
                    <Nav.Item key={itemList.category}>
                      <Nav.Link
                        eventKey={itemList.category}
                        disabled
                        style={{
                          background: `${colors[itemList.category]}`,
                          border: '1px solid #106995',
                          fontWeight:
                            itemList.category === currentCategory
                              ? "bold"
                              : "normal",
                        }}>
                        {tabs[itemList.category]}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {returnedData.map((itemList, i) => (
                    <Tab.Pane key={itemList.category} eventKey={itemList.category}
                    >
                      <MedicalListSelector
                        key={itemList.category}
                        patient={patient}
                        category={itemList.category}
                        message={tabs[itemList.category]}
                        list={itemList.items}
                        index={i}
                        selectedList={selectedData[itemList.category]}
                        toggleFromSelectedList={toggleFromSelectedList}
                        selectOne={switchSingleSelection}
                        setHeadacheParts={setHeadacheParts}
                        headacheParts={headacheParts}
                        symptom_selector
                        noOther
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <Row>
            <Col>
              {showErrorMsg && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  {errorMsg}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
          <Row className="d-flex justify-content-between align-items-center" style={{ marginTop: "1rem" }}>
            <Col xs="auto">
              <SubmitButton
                type="button"
                onClick={handlePrev}
                disabled={currentCategoryIndex === 0}
                greyout={currentCategoryIndex === 0}
              >
                &lt; Previous Category
              </SubmitButton>
            </Col>
            <Col xs="auto">
              <SubmitButton
                type="button"
                onClick={handleNextOrSubmit}
                disabled={submitting}
              >
                {currentCategoryIndex < categories.length - 1
                  ? "Next Category >"
                  : `${SAVE_AND_CONTINUE[language]} >`}
              </SubmitButton>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SelectLaterSymptoms;
