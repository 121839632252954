import React, { useContext, useState } from "react";
import SubmitButton from "./StyledComponents/SubmitButton";
import { PreferredLanguageContext } from "../lib/main-context";
import { PRIVACY_POLICY } from "../constants/PrivacyPolicyTranslation";

const PrivacyPage = ({
  onClick,
  style,
  fromPaymentpage,
  firstName,
  lastName,
  goback,
}) => {
  const { language } = useContext(PreferredLanguageContext);

  return (
    <div style={style}>
      <h3>{PRIVACY_POLICY[language].title}</h3>
      <p>{PRIVACY_POLICY[language].lastUpdated}</p>
      <p>{PRIVACY_POLICY[language].welcome}</p>
      <h4>{PRIVACY_POLICY[language].introductionTitle}</h4>
      <p>{PRIVACY_POLICY[language].introductionText1}</p>
      <p>{PRIVACY_POLICY[language].introductionText2}</p>
      <p>
        <strong>{PRIVACY_POLICY[language].strongText}</strong>
      </p>
      <p>{PRIVACY_POLICY[language].consentText}</p>

      <h4>{PRIVACY_POLICY[language].definitionsTitle}</h4>
      <p>{PRIVACY_POLICY[language].definitionsText1}</p>
      <p>{PRIVACY_POLICY[language].definitionsText2}</p>

      <h4>{PRIVACY_POLICY[language].grantOfLicenseTitle}</h4>
      <p>{PRIVACY_POLICY[language].grantOfLicenseText1}</p>
      <p>{PRIVACY_POLICY[language].grantOfLicenseText2}</p>
      <p>{PRIVACY_POLICY[language].noticesHeading}</p>
      <p>
        <strong>{PRIVACY_POLICY[language].noticesText}</strong>
        {" " + PRIVACY_POLICY[language].noticesText2}
      </p>
      <p>
        <strong>{PRIVACY_POLICY[language].grantOfLicenseText3Heading}</strong>
        {PRIVACY_POLICY[language].grantOfLicenseText3}
      </p>
      <p>
        <strong>{PRIVACY_POLICY[language].grantOfLicenseText4}</strong>
      </p>
      <p>
        <strong>{PRIVACY_POLICY[language].grantOfLicenseText5}</strong>
      </p>

      <h4>{PRIVACY_POLICY[language].feeAndPaymentTitle}</h4>
      <p>{PRIVACY_POLICY[language].feeAndPaymentText1}</p>
      <p>
        <strong>{PRIVACY_POLICY[language].feeAndPaymentStrong1}</strong>
      </p>
      <p>{PRIVACY_POLICY[language].feeAndPaymentText2}</p>
      <p>{PRIVACY_POLICY[language].feeAndPaymentText3}</p>

      <h4>{PRIVACY_POLICY[language].availabilityTitle}</h4>
      <p>
        {PRIVACY_POLICY[language].availabilityText1}{" "}
        <u>{PRIVACY_POLICY[language].availabilityUnderline1}</u>{" "}
        {PRIVACY_POLICY[language].availabilityText2}
        <u>{PRIVACY_POLICY[language].availabilityUnderline2}</u>
        {PRIVACY_POLICY[language].availabilityText3}
      </p>
      <p>
        {PRIVACY_POLICY[language].availabilityText4}
        <u>{PRIVACY_POLICY[language].availabilityUnderline3}</u>
        {PRIVACY_POLICY[language].availabilityText5}
      </p>
      <p>
        {PRIVACY_POLICY[language].availabilityText6}{" "}
        {PRIVACY_POLICY[language].availabilityStrong}
      </p>

      <h4>{PRIVACY_POLICY[language].authorizedUsersTitle}</h4>
      <p>
        {PRIVACY_POLICY[language].authorizedUsersText1}{" "}
        <u>{PRIVACY_POLICY[language].authorizedUsersUnderline1}</u>{" "}
        {PRIVACY_POLICY[language].authorizedUsersText2}{" "}
        <u>{PRIVACY_POLICY[language].authorizedUsersUnderline2}</u>{" "}
        {PRIVACY_POLICY[language].authorizedUsersText3}
      </p>
      <p>{PRIVACY_POLICY[language].authorizedUsersText4}</p>
      <p>
        {PRIVACY_POLICY[language].authorizedUsersText5}{" "}
        {PRIVACY_POLICY[language].authorizedUsersStrong}
      </p>

      <h4>{PRIVACY_POLICY[language].useOfProductTitle}</h4>
      <p>{PRIVACY_POLICY[language].useOfProduct6_1Text}</p>
      <p>{PRIVACY_POLICY[language].useOfProduct6_2Text}</p>
      <p>{PRIVACY_POLICY[language].useOfProduct6_3Text}</p>
      <p>{PRIVACY_POLICY[language].useOfProduct6_4Text}</p>
      <p>{PRIVACY_POLICY[language].useOfProduct6_5Text}</p>

      <h4>{PRIVACY_POLICY[language].contentTitle}</h4>
      <p>{PRIVACY_POLICY[language].content7_1Text + " " + PRIVACY_POLICY[language].content7_1Strong}</p>
      <p>{PRIVACY_POLICY[language].content7_2Text}</p>

      <h4>{PRIVACY_POLICY[language].intellectualPropertyTitle}</h4>
      <p>{PRIVACY_POLICY[language].intellectualProperty8_1Text}</p>
      <p>{PRIVACY_POLICY[language].intellectualProperty8_2Text}</p>
      <p>{PRIVACY_POLICY[language].intellectualProperty8_3Text}</p>
      <p>{PRIVACY_POLICY[language].intellectualProperty8_4Text}</p>

      <h4>{PRIVACY_POLICY[language].accessAndContentTitle}</h4>
      <p>{PRIVACY_POLICY[language].accessAndContent9_1Text}</p>
      <ul>
        {PRIVACY_POLICY[language].accessAndContent9_1List.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <p>{PRIVACY_POLICY[language].accessAndContent9_2Text}</p>
      <p>{PRIVACY_POLICY[language].accessAndContent9_3Text}</p>
      <ul>
        {PRIVACY_POLICY[language].accessAndContent9_3List.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <p>{PRIVACY_POLICY[language].accessAndContent9_4Text}</p>
      <p>{PRIVACY_POLICY[language].accessAndContent9_5Text}</p>

      <h4>{PRIVACY_POLICY[language].warrantyDisclaimerTitle}</h4>
      <p>
        {PRIVACY_POLICY[language].warrantyDisclaimer10_1Text}
      </p>
      <p>
        {PRIVACY_POLICY[language].warrantyDisclaimer10_2Text}
      </p>

      <h4>{PRIVACY_POLICY[language].limitationOfLiabilityTitle}</h4>
      <p>
        {PRIVACY_POLICY[language].limitationOfLiability11_1Text}
      </p>
      <p>
        {PRIVACY_POLICY[language].limitationOfLiability11_2Text}
      </p>


      <h4>{PRIVACY_POLICY[language].termAndTerminationTitle}</h4>
      <p>{PRIVACY_POLICY[language].termAndTermination12_1Text}</p>
      <p>{PRIVACY_POLICY[language].termAndTermination12_2Text}</p>
      <p>{PRIVACY_POLICY[language].termAndTermination12_3Text}</p>
      <p>{PRIVACY_POLICY[language].termAndTermination12_4Text}</p>
      <p>{PRIVACY_POLICY[language].termAndTermination12_5Text}</p>

      <h4>{PRIVACY_POLICY[language].governingLawTitle}</h4>
      <p>{PRIVACY_POLICY[language].governingLaw13_1Text}</p>
      <p>
        {PRIVACY_POLICY[language].governingLaw13_2Text}
      </p>
      <p>{PRIVACY_POLICY[language].governingLaw13_3Text}</p>

      <h4>{PRIVACY_POLICY[language].miscellaneousTitle}</h4>
      <p>{PRIVACY_POLICY[language].miscellaneous14_1Text}</p>
      <p>{PRIVACY_POLICY[language].miscellaneous14_2Text}</p>
      <p>{PRIVACY_POLICY[language].miscellaneous14_3Text}</p>
      <p>{PRIVACY_POLICY[language].miscellaneous14_4Text}</p>

      <p>{PRIVACY_POLICY[language].miscellaneousCompanyAddress}</p>
      <p>{PRIVACY_POLICY[language].miscellaneousUserAddress}</p>

      {!fromPaymentpage && !firstName && !lastName && (
        <SubmitButton onClick={goback} style={{ fontSize: "1.25rem" }}>
          {PRIVACY_POLICY[language].goBackButton}
        </SubmitButton>
      )}
    </div>
  );
};

export default PrivacyPage;
