import React, { useContext, useState, useEffect } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { CHARTS } from "../../constants/DashboardTranslation";

import { Link } from "react-router-dom";

import { Row, Col, Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import ComponentWrapper from "./ComponentWrapper";
import LineChart from "./LineChart";
import TriggerChart from "./TriggerChart";
import SubmitButton from "../StyledComponents/SubmitButton";
import LoadingSpinner from "../LoadingSpinner";

import lineChartIcon from "../../images/show_chart.svg";
import barChartIcon from "../../images/bar_chart.svg";
import LineIcon from "../../images/line.svg";
import PieIcon from "../../images/pie.svg";
import FeelingsIcon from "../../images/overall-feelings.svg";
import BarIcon from "../../images/bar.svg";
import TriggerDataIcon from "../../images/trigger-data.svg";

import { getMostRecentFollowUpForm } from "../../api/TBIRequests";

import downloadFollowUpForm from "../../lib/downloadFollowUpForm";

const Charts = ({ topSymptoms, data, error, retry, additionalNotes, user, patient }) => {
  const { language } = useContext(PreferredLanguageContext);

  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchForm = async (patientId) => {
      try {
        const res = await getMostRecentFollowUpForm({ patientId });
        setFormData(res.data);
      } catch (error) {
        console.error('Error fetching form:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchForm(patient.patientId);
  }, [patient.patientId]);

  const LinkToReport = (title, icon) => (
    <Link to="/charts" className="report-link">
      <img src={icon} alt="icon" className="report-icon" />
      <span style={{ paddingLeft: "0.5rem" }}>{title}</span>
    </Link>
  );

  const lineChartTooltip = (
    <Tooltip id="line-chart-tooltip">{CHARTS[language].trend_charts}</Tooltip>
  );

  const barChartTooltip = (
    <Tooltip id="line-chart-tooltip">{CHARTS[language].trigger_data}</Tooltip>
  );

  return (
    <ComponentWrapper>
      <Tabs id="chartTab">
        {error && (
          <div>
            {CHARTS[language].error[0]}
            <SubmitButton onClick={retry}>{CHARTS[language].error[1]}</SubmitButton>
          </div>
        )}

        <Tab
          eventKey="line"
          title={
            <OverlayTrigger placement="top" overlay={lineChartTooltip}>
              <img src={lineChartIcon} alt="line chart icon" />
            </OverlayTrigger>
          }>
          <LineChart
            topSymptoms={topSymptoms}
            data={data}
            additionalNotes={additionalNotes}
            width={100}
            height={Math.max(topSymptoms.length * 45, 300)}
          />
        </Tab>
        <Tab
          eventKey="bar"
          title={
            <OverlayTrigger placement="top" overlay={barChartTooltip}>
              <img src={barChartIcon} alt="bar chart icon" />
            </OverlayTrigger>
          }
        >
          <TriggerChart data={data} />
        </Tab>
        {user.userType === 'provider' &&
          (<Tab eventKey="Reports" title={CHARTS[language].detailed_reports.label}>
            <Row>
              <Col md={6}>{LinkToReport(CHARTS[language].detailed_reports.overall_feelings, FeelingsIcon)}</Col>
              <Col md={6}>{LinkToReport(CHARTS[language].detailed_reports.top_symptoms, BarIcon)}</Col>
            </Row>
            <Row>
              <Col md={6}>{LinkToReport(CHARTS[language].detailed_reports.medical_score, PieIcon)}</Col>
              <Col md={6}>{LinkToReport(CHARTS[language].detailed_reports.top_triggers, BarIcon)}</Col>
            </Row>
            <Row>
              <Col md={6}>{LinkToReport(CHARTS[language].detailed_reports.trigger_data, TriggerDataIcon)}</Col>
              <Col md={6}>
                {LinkToReport(CHARTS[language].detailed_reports.days_in_review, LineIcon)}
              </Col>
            </Row>
          </Tab>)
        }
        {user.userType === 'provider' &&
          (<Tab eventKey="FollowUpForm" title='Follow Up Form'>
            {loading ? (
              <LoadingSpinner />
            ) : Object.keys(formData).length > 0 ? (
              <div className='followUpFormSummary'>
                <Row>
                  <Col>
                    <SubmitButton
                      onClick={() =>
                        downloadFollowUpForm(
                          formData.answers,
                          `${patient.firstName} ${patient.lastName}`,
                          formData.timestamp
                        )
                      }
                    >
                      Download Most Recent Follow Up Form
                    </SubmitButton>
                  </Col>
                  <Col>
                    <p className='completionDate'><b>Last Completed:</b> {new Date(formData.timestamp).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p><b>Overall Program Done:</b> {formData.answers.overallProgramPercentage}%</p></Col>
                  <Col>
                    <p><b>Overall Progress:</b> {formData.answers.overallProgress}%</p>
                  </Col>
                </Row>
              </div>
            ) : (
              <p className='mt-3 font-weight-normal'>This patient has not yet completed their follow up form.</p>
            )}
          </Tab>)
        }
      </Tabs>
    </ComponentWrapper>
  );
};

export default Charts;
