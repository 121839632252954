/////////////////
// Messages.js //
/////////////////
export const MESSAGES_HEADER = {
    english: {
        header: 'Messages',
        dashboard: 'Go Back to Dashboard',
        send_message: 'Send Message',
        view_all_messages: 'View All Messages',
        select_provider: 'Select Provider'
    },
    spanish: {
        header: 'Mensajes',
        dashboard: 'Volver al Panel de Control',
        send_message: 'Enviar Mensaje',
        view_all_messages: 'Ver Todos los Mensajes',
        select_provider: 'Seleccionar Proveedor'
    }
}

export const MESSAGES_TABLE = {
    english: {
        direction: {
            label: 'Recipient/Sender',
            to: 'To',
            from: 'From'
        },
        provider: 'Provider',
        message: 'Message',
        timestamp: 'Timestamp',
        status: {
            label: 'Status',
            read: 'Read',
            delivered: 'Delivered',
            mark_as_read: 'Mark as Read'
        }
    },
    spanish: {
        direction: {
            label: '"Destinatario/Remitente',
            to: 'A',
            from: 'De'
        },
        provider: 'Proveedor',
        message: 'Mensaje',
        timestamp: 'Fecha y Hora',
        status: {
            label: 'Estado',
            read: 'Leído',
            delivered: 'Entregado',
            mark_as_read: 'Marcar como Leído'
        }
    }
}

export const CLOSE = {
    english: 'Close',
    spanish: 'Cerca'
}

////////////////////
// SendMessage.js //
////////////////////
export const SEND_MESSAGES = {
    english: {
        label: 'You have a connected user and can send messages to them.',
        send_message_to: 'Send message to ',
        back_to_selection: 'Back to selection',
        send: 'Send',
        message_sent: 'A message has been sent to '
    },
    spanish: {
        label: 'Tienes un usuario conectado y puedes enviarle mensajes.',
        send_message_to: 'Enviar mensaje a ',
        back_to_selection: 'Volver a la selección',
        send: 'Enviar',
        message_sent: 'Se ha enviado un mensaje a '
    }
}