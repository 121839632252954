import React, { useState, useEffect, useContext } from "react";
import { ThemeContext, PatientContext, PreferredLanguageContext } from "../lib/main-context";
import { EXPORT_PDF, GO_BACK, OFF_DARK_MODE_EXPORT, CHARTS } from "../constants/DashboardTranslation";

import { useParams, useHistory, useLocation } from "react-router-dom";

import { Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { parse } from "date-fns";

import theme from "../index.scss";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import MedicalDetailsChartOptions from "./DashboardComponents/MedicalDetailsChartOptions";
import ModalPdf from "./DashboardComponents/ModalPdf";
import TriggerChart from './DashboardComponents/TriggerChart';
import SubmitButton from "./StyledComponents/SubmitButton";

import fillInDays from "../lib/fillInDates";
import getDaysAgo from "../lib/getDaysAgo";
import triggerWithSetVal from "../lib/triggerWithSetVal";

import { getAdditionalNote } from "../api/TBIRequests";

const CHART_TYPES = {
    LINE: "line",
    BAR: "bar",
};

const MedicalDetailsChart = ({ error, topSymptoms, data, retry }) => {
    const [type, setType] = useState(CHART_TYPES.LINE);
    const [additionalNotes, setAdditionalNotes] = useState([]);
    const [showLineModal, setShowLineModal] = useState(false);
    const params = useParams();
    const { dark } = useContext(ThemeContext);
    const { patient } = useContext(PatientContext);
    const { language } = useContext(PreferredLanguageContext);

    const history = useHistory();
    const location = useLocation();
    // Access the parameters from location.state if available,
    // otherwise provide default values
    const medicalType = location.state?.medicalType || 'Vision';
    const fromMedicalDetailsChart = location.state?.fromMedicalDetailsChart || false;
    const days = location.state?.days || 7;

    const filteredData = data?.filter(obj => obj.category !== "SDOH").filter(obj => parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) > getDaysAgo(days));

    const trackedSymptoms = [];
    const factorSet = new Set();

    filteredData.forEach((obj) => {
        if (!factorSet.has(obj.factor)) {
            factorSet.add(obj.factor);
            trackedSymptoms.push({
                factor: obj.factor,
                category: obj.category,
                subcategory: obj.subcategory
            });
        }
    });

    const colors = [
        theme.chart1,
        theme.chart2,
        theme.chart3,
        theme.chart4,
        theme.chart5,
        theme.chart6,
        theme.chart7,
        theme.chart8,
        theme.chart9,
        theme.chart10,
        theme.chart1,
        theme.chart2,
        theme.chart3,
        theme.chart4,
        theme.chart5,
        theme.chart6,
        theme.chart7,
        theme.chart8,
        theme.chart9,
        theme.chart10,
        theme.chart1,
        theme.chart2,
        theme.chart3,
        theme.chart4,
        theme.chart5,
        theme.chart6,
        theme.chart7,
        theme.chart8,
        theme.chart9,
        theme.chart10,
    ];

    const handleLineModalClose = () => setShowLineModal(false);
    const handleLineModalShow = () => setShowLineModal(true);
    useEffect(() => {
        getAdditionalNote(patient.patientId)
            .then(({ data }) => {
                setAdditionalNotes(data);
            })
            .catch((e) => console.log("getAdditionalNotesError", e));
    }, []);

    let datasetArr = [];
    trackedSymptoms.forEach((symptom) => {
        let dataset = data.filter(({ factor }) => factor === symptom.factor);

        dataset = dataset.filter(({ subcategory }) => {
            return subcategory?.toLowerCase() === medicalType.toLowerCase();
        });
        datasetArr.push(dataset);
    });


    let triggerList = triggerWithSetVal(data);
    datasetArr.push(triggerList);
    const filterDataByAssignedDays = (dataset) => {
        const datasetConvertedToChartData = dataset.map((item) => {
            return {
                // CURRENT BUG, Must remove time stamp or labels do not work correctly
                x: item.symptom_date.slice(0, 10),
                y: item.severity,
                desc: item.description,
            };
        });
        const filteredByAssignedDays = datasetConvertedToChartData.filter(
            (item) => parse(item.x, "yyyy-MM-dd", new Date()) > getDaysAgo(days)
        );
        return filteredByAssignedDays;
    };

    const newData = {
        datasets: [],
    };
    let { datasets } = newData;

    datasetArr.forEach((dataset, index) => {
        if (dataset.length != 0 && index < datasetArr.length - 1) {
            datasets.push({
                hidden: false,
                fill: type !== "line",
                label: trackedSymptoms[index].factor,
                backgroundColor: colors[index],
                borderColor: colors[index],
                borderWidth: 1,
                hoverBackgroundColor: colors[index],
                hoverBorderColor: "white",
                data: filterDataByAssignedDays(dataset),
            });
        }
        else if (dataset.length != 0) {
            datasets.push({
                label: "Trigger",
                data: filterDataByAssignedDays(dataset),
                pointStyle: "triangle",
                type: "scatter",
                backgroundColor: theme.primary,
                borderWidth: 1,
            });
        }
    });

    const ChartDataset = fillInDays(datasets, days);
    newData.datasets = ChartDataset.slice(0, ChartDataset.length - 1);
    newData.notes = additionalNotes;

    const triggerOptions = {
        animation: {
            duration: 1,
            onComplete: function () {
                // adding trigger count for each day if any. It will be showing on top of the most highest point.
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.textAlign = "center";
                ctx.textBaseline = "bottom";
                ctx.fillStyle = theme.primary;
                const triggerIndex = ChartDataset.length - 1;

                // loop through each dataset again to calculate the change in severity score
                // data will be used to display a red cross if there is a 10% increase
                const severityChangeData = [];
                const symptomDataLength = this.data.datasets[0].data.length;
                let lastVal = null;
                for (let i = 0; i < symptomDataLength; i++) {
                    let curVal = this.data.datasets[0].data[i]?.y?.y;

                    if (curVal == null) {
                        severityChangeData.push(null);
                        continue;
                    }

                    if (lastVal == null) {
                        severityChangeData.push(null);
                        lastVal = curVal;
                        continue;
                    }

                    const change = curVal - lastVal;
                    severityChangeData.push(change);
                    lastVal = curVal;
                }

                const meta = chartInstance.controller.getDatasetMeta(1);
                meta.data.forEach(function (bar, index) {
                    var data = ChartDataset[triggerIndex].data[index].y;
                    if (data) {
                        let triggers = data?.desc ? data.desc.split(",") : [];
                        ctx.fillText(`${triggers.length}`, bar._model.x, bar._model.y - 5);
                    }
                })

                const meta2 = chartInstance.controller.getDatasetMeta(0);
                meta2.data.forEach(function (bar, index) {
                    if (data) {
                        if (severityChangeData[index] !== null && severityChangeData[index] >= 10) {
                            ctx.fillText(`❌`, bar._model.x, bar._model.y + 7);
                        }
                    }
                });
                ctx.fillText(`❌ Above 10% change`, 600, 23);
            },
        },
    }

    const colorOverRide = {
        scales: {
            xAxes: [
                {
                    type: "time",
                    time: {
                        unit: "day",
                        tooltipFormat: "dddd",
                    },
                    ticks: {
                        fontColor: dark ? theme.darkModeText : theme.black,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        fontColor: dark ? theme.darkModeText : theme.black,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Intensity Level",
                        fontColor: dark ? theme.darkModeText : theme.black,
                    },
                },
            ],
        },
    }

    const extendedChartOption = {
        maintainAspectRatio: false,
        title: {
            display: true,
            text: `${medicalType} Report`,
            fontFamily: "quicksand",
            fontStyle: "bold",
            fontColor: dark ? theme.darkModeText : "#212121",
        },
        legend: {
            labels: {
                fontColor: dark ? theme.darkModeText : "#212121",
                usePointStyle: true,
                boxWidth: 8,
                generateLabels: function (chart) {
                    return chart.data.datasets.map((dataset, index) => {
                        const label = dataset.label.length > 50
                            ? dataset.label.slice(0, 50) + "..."
                            : dataset.label;

                        return {
                            text: label,
                            fullText: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            hidden: !chart.isDatasetVisible(index),
                            datasetIndex: index,
                        };
                    });
                },
            },
            onHover: function (event, legendItem) {
                const tooltip = document.getElementById("legend-tooltip");
                tooltip.style.display = "block";
                tooltip.style.left = `${event.clientX - 800}px`;
                tooltip.style.top = `${event.clientY - 300}px`;
                tooltip.innerHTML = legendItem.fullText;
            },
            onLeave: function () {
                const tooltip = document.getElementById("legend-tooltip");
                tooltip.style.display = "none";
            },
        },
    };

    const classNameArr = () => {
        const totalNum = newData?.datasets?.length
        const arr = [];
        for (let i = 0; i < totalNum; i++) {
            arr.push(`${medicalType} ${days} Report${i}`)
        }
        return arr
    }

    if (type === CHART_TYPES.LINE) {
        return (
            <>
                {error && (
                    <ComponentWrapper>
                        {CHARTS[language].error[0]}
                        <SubmitButton onClick={retry}>{CHARTS[language].error[1]}</SubmitButton>
                    </ComponentWrapper>
                )}
                <ComponentWrapper>
                    <Row className="d-flex justify-content-around">
                        <SubmitButton
                            onClick={() => {
                                if (dark) {
                                    alert(OFF_DARK_MODE_EXPORT[language]);
                                } else {
                                    handleLineModalShow();
                                }
                            }}
                        >
                            {EXPORT_PDF[language]}
                        </SubmitButton>
                    </Row>
                </ComponentWrapper>
                <ModalPdf
                    show={showLineModal}
                    handleClose={handleLineModalClose}
                    chart={classNameArr()}
                    isReport={true}
                    data={data}
                    additionalNotes={additionalNotes}
                    days={days}
                    topSymptoms={topSymptoms}
                />

                {newData?.datasets?.map((element, index) => {
                    const newData2 = {
                        datasets: [],
                    };
                    const arr2 = new Array();
                    arr2.push(element);
                    const triggerIndex = ChartDataset.length - 1;
                    const trigger = ChartDataset[triggerIndex];

                    {/* trigger bug, want to use serverity as height*/ }
                    // const arr1 = trigger.data;
                    // for(let i = 0; i < arr1.length; i++){
                    //     let date = arr1[i]?.y?.x;
                    //     let high = -1;
                    //     for(let j = 0; j < element.data.length; j++){
                    //         if(element.data[j].y?.x == date){
                    //             high = element.data[j]?.y?.y;
                    //         }
                    //     }
                    //     if(arr1[i].y){
                    //         arr1[i].y.y = high;
                    //     }
                    // }

                    arr2.push(trigger);
                    newData2.datasets = arr2;
                    newData2.notes = additionalNotes;
                    return (
                        <div className={`${medicalType} ${days} Report${index}`} key={index}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: '2' }}>
                                    <ComponentWrapper key={element.label} >
                                        <Line
                                            data={newData2}
                                            // width={100} 
                                            height={300}
                                            options={{
                                                ...triggerOptions,
                                                ...MedicalDetailsChartOptions,
                                                ...extendedChartOption,
                                                ...colorOverRide,
                                            }}
                                        />
                                        <div
                                            id="legend-tooltip"
                                            style={{
                                                position: "absolute",
                                                display: "none",
                                                backgroundColor: "rgba(0, 0, 0, 0.8)",
                                                color: "#fff",
                                                padding: "5px",
                                                borderRadius: "4px",
                                                fontSize: "12px",
                                                pointerEvents: "none",
                                                whiteSpace: "nowrap",
                                                zIndex: 1000,
                                            }}
                                        ></div>
                                    </ComponentWrapper>
                                </div>
                                <div style={{ flex: '1' }}>
                                    <ComponentWrapper key={element.label} style={{ flexGrow: '1' }}>
                                        <TriggerChart data={data} height={268} stacked={true} />
                                    </ComponentWrapper>
                                </div>
                            </div>
                        </div>

                    )
                })
                }

                <SubmitButton onClick={
                    !fromMedicalDetailsChart ?
                        () => history.push("/") : () => history.push({ pathname: "/charts", state: { days: days } })
                }>{GO_BACK[language]}</SubmitButton>
            </>
        );
    } else if (type === CHART_TYPES.BAR) {
        return (
            <>
                {error && (
                    <ComponentWrapper>
                        {CHARTS[language].error[0]}
                        <SubmitButton onClick={retry}>{CHARTS[language].error[1]}</SubmitButton>
                    </ComponentWrapper>
                )}
                <ComponentWrapper>
                    <Row className="d-flex justify-content-around">
                        <SubmitButton onClick={() => setType("line")}>
                            Change to line chart
                        </SubmitButton>
                        <SubmitButton
                            onClick={() => {
                                if (dark) {
                                    alert(OFF_DARK_MODE_EXPORT[language]);
                                }
                            }}
                        >
                            {EXPORT_PDF[language]}
                        </SubmitButton>
                    </Row>
                </ComponentWrapper>
                <SubmitButton onClick={() => history.push("/")}>{GO_BACK[language]}</SubmitButton>
            </>
        );
    }
};

export default MedicalDetailsChart;
