import React, { useContext, useState, useEffect } from "react";
import { ThemeContext, PreferredLanguageContext } from "../lib/main-context";
import { MESSAGES_HEADER, MESSAGES_TABLE, CLOSE } from "../constants/MessagesTranslation";

import { useHistory, useLocation } from "react-router-dom";

import theme from "../index.scss";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SubmitButton from "./StyledComponents/SubmitButton";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import SendMessage from "./DashboardComponents/SendMessage";
import SelectProviderDropdown from './SelectProviderDropdown'

import { updateMessageRead, getProviderList } from "../api/messageRequests";

import useWindowSize from "../lib/useWindowSize";

const Messages = ({ user, messages, setMessages }) => {
    const history = useHistory();
    const location = useLocation();
    const [width] = useWindowSize();

    const { language } = useContext(PreferredLanguageContext);
    const { dark } = useContext(ThemeContext);
    const { curPatient } = location.state

    const [currentProviderId, setCurrentProviderId] = useState(null);
    const [currentProviderName, setCurrentProviderName] = useState(null);
    const [showSendMessage, setShowSendMessage] = useState(false);
    const [providerList, setProviderList] = useState([]);

    // utility function
    const capitalizeName = (firstName, lastName) =>
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase() + ' ' +
        lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
    // end utility function

    useEffect(() => {
        const fetchProviderList = async () => {
            try {
                const res = await getProviderList();
                const providers = res.data
                const formattedProviders = providers.map(provider => ({
                    value: provider.provider_id,
                    label: provider.full_name
                }));
                setProviderList(formattedProviders);
            } catch (err) {
                console.error('Error fetching provider list', err)
            }
        }

        fetchProviderList();
    }, []);

    const setMessageAsRead = ({ messageId }) => {
        updateMessageRead({ messageId })
            .then(() => {
                setMessages((originalMessages) => {
                    const messageIndex = originalMessages.findIndex((message) => message.messageId === messageId);
                    const updatedMessages = [...originalMessages];
                    updatedMessages[messageIndex] = {
                        ...updatedMessages[messageIndex],
                        hasBeenRead: true,
                    };
                    return updatedMessages;
                });
            })
            .catch((err) => {
                console.error(err);
                history.push("/oops");
            });
    };

    const toggleShowSendMessage = () => setShowSendMessage(prevState => !prevState);

    const filteredMessages = currentProviderId
        ? messages.filter(m => m.providerId === currentProviderId)
        : messages;

    const columns = [
        {
            dataField: 'messageId',
            hidden: "true"
        },
        {
            dataField: 'direction',
            text: 'Sender',
            formatter: (direction, row) => {
                if (direction === 'to') {
                    return curPatient.caregiverName ? curPatient.caregiverName : capitalizeName(curPatient.firstName, curPatient.lastName)
                } else {
                    return row.providerName
                }
            }
        },
        {
            dataField: 'direction',
            text: 'Recipient',
            formatter: (direction, row) => {
                if (direction === 'from') {
                    return capitalizeName(curPatient.firstName, curPatient.lastName)
                } else {
                    return row.providerName
                }
            }
        },
        {
            dataField: 'body',
            text: MESSAGES_TABLE[language].message
        },
        {
            dataField: 'timestamp',
            text: MESSAGES_TABLE[language].timestamp
        },
        {
            dataField: 'hasBeenRead',
            text: MESSAGES_TABLE[language].status.label,
            formatter: (hasBeenRead, row) => {
                const isProvider = user.userType === 'provider';
                const isPatient = user.userType !== 'provider';
                const belongsToProvider = row.providerId === user.userId;
                const isFromDirection = row.direction === 'from';
                const isToDirection = row.direction === 'to';

                const showMarkAsReadButton =
                    // Patient: Message is from a provider, and unread
                    (!hasBeenRead && isPatient && isFromDirection) ||
                    // Provider: Message is to the provider, and unread, with matching providerId
                    (!hasBeenRead && isProvider && isToDirection && belongsToProvider);

                if (showMarkAsReadButton) {
                    return (
                        <SubmitButton centered onClick={() => {
                            setMessageAsRead({ messageId: row.messageId });
                        }}>
                            {MESSAGES_TABLE[language].status.mark_as_read}
                        </SubmitButton>
                    );
                }

                const value = hasBeenRead ? 'read' : 'delivered';

                return MESSAGES_TABLE[language].status[value];
            }
        }

    ];

    const rowStyle = (row) => {
        const style = {};
        style.overflowWrap = "break-word";

        const isProvider = user.userType === 'provider';
        const isPatient = user.userType !== 'provider';
        const belongsToProvider = row.providerId === user.userId;
        const isFromDirection = row.direction === 'from';
        const isToDirection = row.direction === 'to';

        const canMarkAsRead =
            // Patient: Message is from a provider, and unread
            (!row.hasBeenRead && isPatient && isFromDirection) ||
            // Provider: Message is to the provider, and unread, with matching providerId
            (!row.hasBeenRead && isProvider && isToDirection && belongsToProvider);

        if (canMarkAsRead) {
            style.backgroundColor = '#cfe8e6';
        }

        return style;
    };

    let messagesDisplay;

    if (width >= 768) {
        messagesDisplay = (
            <BootstrapTable
                className="overflow-hidden"
                keyField="messageId"
                data={filteredMessages}
                columns={columns}
                pagination={paginationFactory()}
                rowStyle={rowStyle}
            />
        );
    } else {
        messagesDisplay = filteredMessages.map((message) => (
            <Row className="font-weight-normal" key={message.messageId}>
                <Col className={`mb-3 border border-dark rounded${!message.hasBeenRead ? ' table-info font-weight-bold' : ''}`}>
                    <h4 className="text-center">{message.providerName}</h4>
                    <h4 className="text-center">{message.direction}</h4>
                    <h4 className="text-center">{message.timestamp}</h4>
                    <p>{message.body}</p>
                    {!message.hasBeenRead && (
                        <SubmitButton centered onClick={() => setMessageAsRead({ messageId: message.messageId })}>
                            {MESSAGES_TABLE[language].status.mark_as_read}
                        </SubmitButton>
                    )}
                </Col>
            </Row>
        ));
    }

    const handleDropdownChange = (selectedOption) => {
        setCurrentProviderId(selectedOption ? selectedOption.value : null);
        setCurrentProviderName(selectedOption ? selectedOption.label : null);
    };

    // to be used to highlight the unique provider ids with unread messages for the patient
    const uniqueUnreadUserIds =
        [...new Set(
            messages?.filter(message => !message.hasBeenRead && message.direction === 'from').map(message => message.providerId)
        )];

    return (
        <ComponentWrapper style={{ padding: "60px" }}>
            <h1 className="text-center mb-3">{MESSAGES_HEADER[language].header}</h1>
            <>
                <Modal className="d-flex align-items-center" show={showSendMessage} onHide={toggleShowSendMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendMessage
                            recipientId={user.userType !== 'provider' ? currentProviderId : curPatient.patientId}
                            recipientName={user.userType !== 'provider' ? currentProviderName : capitalizeName(curPatient.firstName, curPatient.lastName)}
                            userType={user.userType}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggleShowSendMessage}>
                            {CLOSE[language]}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            <Row>
                <Col className="align-items-end">
                    <div className="mb-4">
                        <h6>{MESSAGES_HEADER[language].select_provider}</h6>
                        <SelectProviderDropdown
                            options={providerList}
                            onChange={(handleDropdownChange)}
                            selectedProviderId={currentProviderId}
                            uniqueUnreadUserIds={uniqueUnreadUserIds}
                            userType={user.userType}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={`d-flex flex-wrap align-items-end ${width >= 768 ? "justify-content-start" : "justify-content-center"}`}>
                    <h6
                        className="pt-2 pb-2 mb-4"
                        style={{
                            width: "90%",
                            borderRadius: "20px",
                            backgroundColor: dark ? theme.darkModePrimary : "#3EB1A6",
                            color: dark ? theme.darkModeText : "white",
                            textAlign: "center",
                            overflowWrap: "break-word",
                            cursor: "pointer"
                        }}
                        onClick={() => history.push("/")}
                    >
                        {MESSAGES_HEADER[language].dashboard}
                    </h6>
                </Col>
                <Col className={`d-flex flex-wrap align-items-end ${width >= 768 ? "justify-content-start" : "justify-content-center"}`}>
                    <h6
                        className="pt-2 pb-2 mb-4"
                        style={{
                            width: "90%",
                            borderRadius: "20px",
                            backgroundColor: dark ? theme.darkModePrimary : "#3EB1A6",
                            color: dark ? theme.darkModeText : "white",
                            textAlign: "center",
                            overflowWrap: "break-word",
                            cursor: "pointer",
                        }}
                        onClick={toggleShowSendMessage}
                    >
                        {MESSAGES_HEADER[language].send_message}
                    </h6>
                </Col>
                <Col className={`d-flex flex-wrap align-items-end ${width >= 768 ? "justify-content-start" : "justify-content-center"}`}>
                    <h6
                        className="pt-2 pb-2 mb-4"
                        style={{
                            width: "90%",
                            borderRadius: "20px",
                            backgroundColor: dark ? theme.darkModePrimary : "#3EB1A6",
                            color: dark ? theme.darkModeText : "white",
                            textAlign: "center",
                            overflowWrap: "break-word",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            setCurrentProviderId(null);
                            setCurrentProviderName(null);
                        }}
                    >
                        {MESSAGES_HEADER[language].view_all_messages}
                    </h6>
                </Col>
            </Row>
            {messagesDisplay}
        </ComponentWrapper >
    )
}

export default Messages