import React, { useEffect, useState, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { EDIT_NOTIFICATION, EDIT } from "../../constants/DashboardTranslation";

import { Link } from "react-router-dom";

import { Row, Col, Form } from "react-bootstrap";
import EditIcon from "../../images/edit.svg";
import ComponentWrapper from "../DashboardComponents/ComponentWrapper";
import Doghouse from "../DashboardComponents/Doghouse";

import { getTrackingPrefs } from "../../api/auth";

const EditNotification = () => {
  const { language } = useContext(PreferredLanguageContext);

  const [timeString, setTimeString] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState([]);
  const [sallieSleep, setSallieSleep] = useState(timeString === "");

  const DaysOfWeek = ({ selectedDays }) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const parsedSelectedDays = selectedDays.map((day) => JSON.parse(day));
    return (
      <Form>
        <Row>
          {daysOfWeek.map((day, index) => (
            <Col key={day} sm={4} md={6} lg={4}>
              <Form.Check
                type="checkbox"
                name="dayOfWeek"
                label={EDIT_NOTIFICATION[language].day_of_week[day]}
                value={day}
                checked={parsedSelectedDays[index]}
                style={{ fontWeight: "normal" }}
                readOnly
              />
            </Col>
          ))}
        </Row>
      </Form>
    );
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const result = await getTrackingPrefs();
        if (isMounted && result.data.email_alert && result.data.time_alert) {
          const time = result.data.time_alert.slice(0, 2);
          const isAM = time < 12;
          const formattedTime = formatTime(time, isAM);
          const dayOfWeek = result.data.day_of_week.split(",");
          setDayOfWeek(dayOfWeek);
          setTimeString(formattedTime);
        } else if (!result.data.email_alert || !result.data.time_alert) {
          setDayOfWeek([false, false, false, false, false, false, false]);
          setTimeString("");
        }
      } catch (error) {
        console.error("Error fetching tracking preferences:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [sallieSleep]);
  const formatTime = (time, isAM) => {
    const formattedHour = isAM ? time : time - 12;
    const partOfDay = isAM ? "AM" : "PM";
    return `${formattedHour}:00 ${partOfDay}`;
  };

  return (
    <>
      <ComponentWrapper>
        <h6>{EDIT_NOTIFICATION[language].header}</h6>
        <div>
          <span>{timeString}</span>
        </div>
        <DaysOfWeek selectedDays={dayOfWeek} />
        <Link
          className="d-flex justify-content-end align-items-center"
          to="/accountSetting/ChangeNotification"
        >
          <img src={EditIcon} alt="edit icon" className="editIcon" />
          <span>{EDIT[language]}</span>
        </Link>
      </ComponentWrapper>
      <Doghouse
        timeString={timeString}
        sallieSleep={sallieSleep}
        setSallieSleep={setSallieSleep}
      />
    </>
  );
};
export default EditNotification;
