import React from 'react';
import Select from 'react-select';

const SelectProviderDropdown = ({ options, onChange, uniqueUnreadUserIds, selectedProviderId, userType }) => {
    const customStyles = {
        option: (provided, state) => {
            const backgroundColor = state.data && uniqueUnreadUserIds.includes(state.data.value) ? '#cfe8e6' : 'transparent';
            const color = state.isSelected ? 'black' : 'inherit';
            return {
                ...provided,
                backgroundColor,
                color,
                '&:hover': {
                    backgroundColor: 'lightblue',
                },
            };
        },
    };

    const value = selectedProviderId ? options.find(option => option.value === selectedProviderId) : null;

    return (
        <Select
            options={options}
            onChange={onChange}
            value={value}
            placeholder="Select a Provider..."
            {...(userType !== 'provider' && { styles: customStyles })}
        />
    );
};

export default SelectProviderDropdown;
