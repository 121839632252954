import axios from "axios";
import apiUrl from "./apiConfig";

// 11/22/21 Not sure what this is or if it's necessary, but I'm keeping it because it was in TBIRequests
axios.defaults.withCredentials = true;

export const sendMessage = (data) => {
  return axios.post(`${apiUrl}/sendMessage`, data);
};

export const getMessages = (patientId) => {
  return axios.get(`${apiUrl}/getMessages`, {
    params: { patientId }
  });
};

export const updateMessageRead = ({ messageId }) => {
  return axios.put(`${apiUrl}/updateMessageRead`, {}, {
    params: { messageId }
  });
};

export const deleteMessage = ({ messageId }) => {
  return axios.delete(`${apiUrl}/deleteMessage`, { params: { messageId } });
}

export const getProviderList = () => {
  return axios.get(`${apiUrl}/getProviderList`)
}