import React, { useContext, useState } from "react";
import { ThemeContext, PreferredLanguageContext } from "../../lib/main-context";
import { EXPORT_PDF, OFF_DARK_MODE_EXPORT } from "../../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { parse } from "date-fns";

import theme from "../../index.scss";
import SubmitButton from "../StyledComponents/SubmitButton";
import ModalPdf from "./ModalPdf";
import options from "./LineChartOptions";

import fillInDates from "../../lib/fillInDates";
import getDaysAgo from "../../lib/getDaysAgo";
import triggerWithSetVal from "../../lib/triggerWithSetVal";

const LineChart = ({ topSymptoms, data, additionalNotes, height }) => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const { dark } = useContext(ThemeContext);
  const { language } = useContext(PreferredLanguageContext);

  const colors = [
    "#fdfdfd", "#1d1d1d", "#ebce2b", "#702c8c", "#db6917", "#96cde6", "#ba1c30",
    "#c0bd7f", "#7f7e80", "#5fa641", "#d485b2", "#4277b6", "#df8461", "#463397",
    "#e1a11a", "#91218c", "#e8e948", "#7e1510", "#92ae31", "#6f340d", "#d32b1e",
    "#2b3514"
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let datasetArr = [];
  topSymptoms.forEach((symptom) => {
    let dataset = data?.filter(({ factor }) => factor === symptom.factor);
    datasetArr.push(dataset);
  });
  let triggerList = triggerWithSetVal(data);
  datasetArr.push(triggerList);

  const mapData = (dataset) => {
    return (
      dataset?.map((item) => {
        const date = item.symptom_date.slice(0, 10);
        return {
          // CURRENT BUG, Must remove time stamp or labels do not work correctly
          x: date,
          y: item.severity,
          desc: item.description,
        };
      })
        // needs to return > 8 days ago because the times will never match perfectly
        .filter(
          (item) => parse(item.x, "yyyy-MM-dd", new Date()) > getDaysAgo(7)
        )
    );
  };

  const newData = {
    datasets: [],
  };
  let { datasets } = newData;

  datasetArr.forEach((dataset, index) => {
    if (index < datasetArr.length - 1) {
      datasets.push({
        fill: false,
        label: topSymptoms[index].factor,
        backgroundColor: colors[index],
        borderColor: colors[index],
        borderWidth: 1,
        hoverBackgroundColor: colors[index],
        hoverBorderColor: "white",
        pointBorderWidth: 4,
        data: mapData(dataset),
      });
    } else {
      datasets.push({
        label: "Trigger",
        data: mapData(dataset),
        pointStyle: "triangle",
        type: "scatter",
        backgroundColor: theme.primary,
        borderWidth: 1,
      });
    }
  });

  datasets = fillInDates(datasets, 7);
  newData.notes = additionalNotes;

  const handleClick = (e) => {
    try {
      // set the selected symptom to the index of the clicked dataset
      // eslint-disable-next-line no-underscore-dangle
      const symptomIndex = e[0]._datasetIndex;
      history.push(
        `/charts/${encodeURIComponent(topSymptoms[symptomIndex].factor)}`
      );
    } catch {
      // if there is an error, it's because the user did not click on
      // an element of the chart
    }
  };

  options.scales.xAxes[0].ticks = {
    fontColor: dark ? theme.darkModeText : "#666",
  };
  options.scales.yAxes[0].ticks = {
    beginAtZero: true,
    fontColor: dark ? theme.darkModeText : "#666",
  };

  return (
    <>
      <div className="lineChart">
        <Line
          getDatasetAtEvent={handleClick}
          data={newData}
          width={100}
          height={500}
          options={{
            ...options,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: "Week in Review",
              fontFamily: "quicksand",
              fontStyle: "bold",
              fontColor: dark ? theme.darkModeText : "#666",
            },
            legend: {
              position: "bottom",
              labels: {
                fontColor: dark ? theme.darkModeText : "#666",
                usePointStyle: true,
                boxWidth: 8,
                generateLabels: function (chart) {
                  return chart.data.datasets.map((dataset, index) => {
                    const label = dataset.label.length > 30
                      ? dataset.label.slice(0, 30) + "..."
                      : dataset.label;

                    return {
                      text: label,
                      fullText: dataset.label,
                      fillStyle: dataset.backgroundColor,
                      hidden: !chart.isDatasetVisible(index),
                      datasetIndex: index,
                    };
                  });
                },
              },
              onHover: function (event, legendItem) {
                const tooltip = document.getElementById("legend-tooltip");
                tooltip.style.display = "block";
                tooltip.style.left = `${event.clientX - 1000}px`;
                tooltip.style.top = `${event.clientY}px`;
                tooltip.innerHTML = legendItem.fullText;
              },
              onLeave: function () {
                const tooltip = document.getElementById("legend-tooltip");
                tooltip.style.display = "none";
              },
            },
          }}
        />
        <div
          id="legend-tooltip"
          style={{
            position: "absolute",
            display: "none",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            padding: "5px",
            borderRadius: "4px",
            fontSize: "12px",
            pointerEvents: "none",
            whiteSpace: "nowrap",
          }}
        ></div>
      </div>
      <SubmitButton
        onClick={() => {
          if (dark) {
            alert(OFF_DARK_MODE_EXPORT[language]);
          } else {
            handleShow();
          }
        }}
      >
        {EXPORT_PDF[language]}
      </SubmitButton>
      <ModalPdf
        show={show}
        handleClose={handleClose}
        chart="lineChart"
        data={data}
        additionalNotes={additionalNotes}
        days={7}
      />
    </>
  );
};

export default LineChart;
