import React, { useState, useContext } from "react";
import { PreferredLanguageContext } from "../lib/main-context";
import { CHOOSE, OTHER } from "../constants/OnboardingTranslation";
import MedicalPillButton from "./StyledComponents/MedicalPillButton";
import { Col } from "react-bootstrap";

const DiagnosisSelector = ({
  items,
  selectedItems,
  selectItem
}) => {
  const NUM_COLUMNS = 4
  const itemsPerColumn = items.length / NUM_COLUMNS

  const groups = []
  for (let i = 0; i < items.length; i += itemsPerColumn) {
    groups.push(items.slice(i, i + itemsPerColumn));
  }
  
  return (
    <div style={{ display: "flex" }} className="no-gutters mb-4">
      {groups.map((groupItems, i) => {
        return (
          <DiagnosisSubSelector
            items={groupItems}
            selectedItems={selectedItems}
            selectItem={selectItem}
            width={12 / NUM_COLUMNS}
          />
        );
      })}
    </div>
  )
}

const DiagnosisSubSelector = ({
  items,
  selectedItems,
  selectItem,
  width
}) => {
  const { language } = useContext(PreferredLanguageContext);

  return (
    <Col md={width} className="listSelector">
      <div className="justify-content-left">
        {items?.map((item) => (
          <div key={item.id}>
            <MedicalPillButton
              onClick={() => selectItem(item.id)}
              variant={
                // selectedList.includes(selectableItem) ? "info" : "secondary"
                "light"
              }
              key={item.id}
              style={{ color: "black", border: " white", textAlign: "left" }}
            >
              {selectedItems?.includes(item.id) ? (
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <title>icon/action/check_ccircle_24px</title>
                  <defs>
                    <path
                      d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
                      id="path-1"
                    ></path>
                  </defs>
                  <g
                    id="icon/action/check_ccircle_24px"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-1"></use>
                    </mask>
                    <use
                      id="-↳Color"
                      fill={"#106995"}
                      xlinkHref="#path-1"
                    ></use>
                  </g>
                </svg>
              ) : (
                <svg width="24px" height="24px" viewBox="0 0 22 22">
                  <circle
                    cx="11"
                    cy="11"
                    r="9.5"
                    stroke="grey"
                    strokeWidth="1"
                    fill="white"
                  />
                </svg>
              )}{" "}
              {item.diagnosis}
            </MedicalPillButton>
          </div>
        ))}
      </div>
    </Col>
  );
};

export default DiagnosisSelector;
