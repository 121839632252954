import React, { useState, useEffect, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { PATIENT_CONTROL } from "../../constants/DashboardTranslation";

import { useHistory, Redirect, Link } from "react-router-dom";

import "../../index.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Select from "react-select";

import Sallie from "../Sallie";
import PillButton from "../StyledComponents/PillButton";
import ComponentWrapper from "./ComponentWrapper";
import SmLinkButton from "../StyledComponents/smLinkButton";

const SELECTED_PATIENT = "patient"
const SELECTED_CAREGIVER_PATIENT = "caregiver_patient"

const PatientControl = ({
  user,
  patientList,
  caregiverList,
  setPatient,
  setUserIdForTrackedData,
}) => {
  const { language } = useContext(PreferredLanguageContext);

  const [currentlySelectedType, setCurrentlySelectedType] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedCaregiverId, setSelectedCaregiverId] = useState(null);
  const [selectedCaregiverPatientId, setSelectedCaregiverPatientId] = useState(null);

  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [noPatientError, setNoPatientError] = useState(false);

  useEffect(() => {
    if (
      !(user.userType == "caregiver" ||
        user.userType == "provider"
      )
    )
      history.push("/");
  }, []);

  const handleSubmit = (patient) => {
    setSubmitting(true);
    if (!patient) {
      setNoPatientError(true);
    } else {
      setPatient(patient);
      setUserIdForTrackedData(patient.caregiverId);
      history.push("/");
    }
    setSubmitting(false);
  };

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/signIn",
        }}
      />
    );
  }

  const selectedPatientObj = selectedPatientId && patientList.find(
    (patient) => patient.patientId === selectedPatientId
  )

  const selectedCaregiverObj = selectedCaregiverId && caregiverList.find(
    (caregiver) => caregiver.caregiverId === selectedCaregiverId
  )

  const selectedCaregiverPatientObj = selectedCaregiverPatientId && caregiverList.find(
    (patient) => patient.patientId === selectedCaregiverPatientId
  )

  let currentlySelectedObj = null;
  if (currentlySelectedType === SELECTED_PATIENT) {
    currentlySelectedObj = selectedPatientObj;
  } else if (currentlySelectedType === SELECTED_CAREGIVER_PATIENT) {
    currentlySelectedObj = selectedCaregiverPatientObj;
  }

  const patientListOptions = patientList
    ?.map((patient) => ({
      value: patient.patientId,
      label: `${patient.firstName} ${patient.lastName}${patient.externalId ? `, ID: ${patient.externalId}` : ""}${patient.incompletedRegister ? PATIENT_CONTROL[language].patient_list_options.incomplete_registration : ""
        } ${patient.patientStatus === "Released" ? PATIENT_CONTROL[language].patient_list_options.released : ""}`,
    }))
    .sort((a, b) => a?.label.localeCompare(b?.label));

  let uniqueCaregivers = [];
  caregiverList &&
    caregiverList.forEach((c) => {
      let isUnique = true;
      for (const unique of uniqueCaregivers) {
        if (unique.caregiverName == c.caregiverName) {
          isUnique = false;
          break
        }
      }
      if (isUnique) {
        uniqueCaregivers.push(c);
      }
    });

  const caregiverOptions = uniqueCaregivers
    ?.map((caregiver) => ({
      value: caregiver.caregiverId,
      label: caregiver.caregiverName
    }))
    .sort((a, b) => a?.label.localeCompare(b?.label));

  const selectedCaregiverPatients = selectedCaregiverObj && caregiverList.filter((item) => item.caregiverName === selectedCaregiverObj.caregiverName)

  const selectedCaregiverPatientOptions = selectedCaregiverPatients && selectedCaregiverPatients
    ?.map((patient) => ({
      value: patient.patientId,
      label: `${patient.firstName} ${patient.lastName}
        ${patient.externalId
          ? `, ID: ${patient.externalId}`
          : ""
        }
        ${patient.incompletedRegister
          ? PATIENT_CONTROL[language].patient_list_options.incomplete_registration
          : ""
        } 
        ${patient.patientStatus === "Released"
          ? PATIENT_CONTROL[language].patient_list_options.released
          : ""
        }`,
    }))
    .sort((a, b) => a?.label.localeCompare(b?.label));

  return (
    <div>
      <Sallie
        text={
          noPatientError
            ? PATIENT_CONTROL[language].sallie.no_patient_error
            : user.userType === "provider"
              ? PATIENT_CONTROL[language].sallie.provider
              : PATIENT_CONTROL[language].sallie.non_provider
        }
      />
      <div className="w-100 p-3 mx-auto">
        <ComponentWrapper>
          <h6 className="d-block mb-4">
            {patientList && patientList.length === 0
              ? PATIENT_CONTROL[language].patient_select.no_patient_error
              : user.userType == "provider"
                ? PATIENT_CONTROL[language].patient_select.provider
                : PATIENT_CONTROL[language].patient_select.non_provider}
          </h6>
          <Col>
            <Row className="d-flex justify-content-center">
              {patientList && (
                <Col md={4}>
                  <div className="mb-4">
                    <h5 className="mb-4">{PATIENT_CONTROL[language].patient_list.label}</h5>
                    <Select
                      options={patientListOptions}
                      value={selectedPatientId ?
                        patientListOptions.find((option) => option.value === selectedPatientId)
                        : null
                      }
                      placeholder={PATIENT_CONTROL[language].patient_list_options.select}
                      onChange={(selectedOption) => {
                        setSelectedPatientId(selectedOption.value);
                        setCurrentlySelectedType(SELECTED_PATIENT);
                        setSelectedCaregiverId(null);
                      }}
                    />
                  </div>
                </Col>
              )}

              {user.userType === "provider" && caregiverList && (
                <Col md={4}>
                  <div className="mb-4">
                    <h5 className="mb-4">{PATIENT_CONTROL[language].caregiver_list.label}</h5>
                    <Select
                      options={caregiverOptions}
                      value={selectedCaregiverId ?
                        caregiverOptions.find((caregiver) => caregiver.value === selectedCaregiverId)
                        : null
                      }
                      placeholder={PATIENT_CONTROL[language].caregiver_list.select}
                      onChange={(selectedOption) => {
                        setSelectedCaregiverId(selectedOption.value);
                        setSelectedCaregiverPatientId(null);
                        setCurrentlySelectedType(null);
                        setSelectedPatientId(null);
                      }} />
                  </div>
                  {selectedCaregiverId &&
                    <div className="mb-4">
                      <h5 className="mb-4"> {PATIENT_CONTROL[language].caregiver_list.patient_list(selectedCaregiverObj.caregiverName)} </h5>
                      <Select
                        options={selectedCaregiverPatientOptions}
                        value={selectedCaregiverPatientId ?
                          selectedCaregiverPatientOptions.find((option) => option.value === selectedCaregiverPatientId)
                          : null
                        }
                        label='test'
                        placeholder={PATIENT_CONTROL[language].patient_list_options.select}
                        onChange={(selectedOption) => {
                          setSelectedCaregiverPatientId(selectedOption.value);
                          setCurrentlySelectedType(SELECTED_CAREGIVER_PATIENT);
                        }}
                      />
                    </div>
                  }
                </Col>
              )}
            </Row>

            <Row className="d-flex justify-content-center">
              {currentlySelectedObj &&
                <div className="mb-4">
                  <PillButton
                    variant={
                      currentlySelectedObj.patientStatus === "Released"
                        ? "gray"
                        : "secondary"
                    }
                    onClick={() => {
                      handleSubmit(currentlySelectedObj);
                    }}
                    disabled={
                      currentlySelectedObj.incompletedRegister
                      && user.userType === 'provider'}
                  >
                    {PATIENT_CONTROL[language].submit(currentlySelectedObj.firstName)}
                  </PillButton>
                </div>
              }
            </Row>
          </Col>
        </ComponentWrapper>

        {user.userType === "caregiver" ? (
          <div style={{ fontSize: "1.5rem" }}>
            {PATIENT_CONTROL[language].add_patient[0]}
            <SmLinkButton>
              <Link
                to={{
                  pathname: "/RegisterPatient",
                  state: { fromPatientControl: true },
                }}
              >
                {PATIENT_CONTROL[language].add_patient[1]}
              </Link>
            </SmLinkButton>
          </div>
        ) : null}

      </div>
    </div>
  );
};

export default PatientControl;
